import React from 'react'
import countBg from '../../assets/images/30.jpeg'
import './Countdown.css'
var countDate = new Date("Dec 31, 2023 00:00:00").getTime();
var day = null;
var hour = null;
var min = null;
var sec = null;
function finalDay(){
    var now = new Date().getTime();
    var gap = countDate - now;

        var second = 1000;
        var minute = second * 60;
        var hour = minute * 60;
        var day = hour * 24;

        var d = Math.floor(gap / (day));
        var h = Math.floor((gap % (day)) / (hour));
        var m = Math.floor((gap % (hour)) / (minute));
        var s = Math.floor((gap % (minute)) / second);
        if (document.getElementById("day") != null){
            document.getElementById("day").innerHTML = d;
        }
        if (document.getElementById("hour") != null){
            document.getElementById("hour").innerHTML = h;
        }
        if (document.getElementById("minute") != null){
            document.getElementById("minute").innerHTML = m;
        }
        if (document.getElementById("second") != null){
            document.getElementById("second").innerHTML = s;
        }
}


function Countdown() {
  return (
    <section className="h-screen w-screen mt-[10rem]">
        <div className="h-1/2 w-full relative flex">
            <div class="absolute top-0 right-0 bottom-0 left-0 overflow-hidden z-[-2] bg-blend-hard-light">
                <img src={countBg}
                     className="absolute w-full h-full object-cover"/>
            </div>
            <div className="bgSmaller absolute top-[40px] right-[70px] left-[70px] bottom-[40px] overflow-hidden z-[-1]">
                <img src={countBg}
                     className="absolute w-full h-full object-cover"/>
            </div>
            <h2 className='h-full w-full absolute top-0 left-0'>
                <div className="top-1/4 absolute w-full flex flex-col text-center">
                    <div className='block font-semibold text-[.8em] leading-[.7em] tracking-[0.375rem] text-main-color mb-5'>
                        Numaram impreuna minutele
                    </div>
                    <div className="text-[3em] sm:text-[4em] text-main-color font-normal leading-[.7em]">
                        pana la revelion
                    </div>
                    
                </div>
            </h2>
            <div className="absolute h-full w-full top-0 left-0">
                <div className="countdown flex flex-row justify-center h-full">
                <div>
                    <div className="top-1/2 countDiv relative before:content-none before:absolute before:bottom-[-30px] before:left-0 before:w-[100%] before:h-[35px]
                                before:bg-[#ff0] before:text-[#333] before:text-[0.35em] before:leading-[35px] before:font-light w-[3.125rem] h-[3.125rem] sm:w-[6.25rem] sm:h-[6.25rem] 
                                leading-[3.125rem] sm:leading-[100px] text-center bg-[#333] text-[#fff] my-0 mx-[15px] text-[2em] sm:text-[3em] font-medium" id="day">NA</div>
                </div>
                <div>
                    <div className="top-1/2 countDiv relative before:content-none before:absolute before:bottom-[-30px] before:left-0 before:w-[100%] before:h-[35px]
                                before:bg-[#ff0] before:text-[#333] before:text-[0.35em] before:leading-[35px] before:font-light w-[3.125rem] h-[3.125rem] sm:w-[6.25rem] sm:h-[6.25rem] 
                                leading-[3.125rem] sm:leading-[100px] text-center bg-[#333] text-[#fff] my-0 mx-[15px] text-[2em] sm:text-[3em] font-medium" id="hour" >NA</div>
                </div>
                <div>
                    <div className="top-1/2 countDiv relative before:content-none before:absolute before:bottom-[-30px] before:left-0 before:w-[100%] before:h-[35px]
                                before:bg-[#ff0] before:text-[#333] before:text-[0.35em] before:leading-[35px] before:font-light w-[3.125rem] h-[3.125rem] sm:w-[6.25rem] sm:h-[6.25rem] 
                                leading-[3.125rem] sm:leading-[100px] text-center bg-[#333] text-[#fff] my-0 mx-[15px] text-[2em] sm:text-[3em] font-medium" id="minute">NA</div>
                </div>
                <div>
                <div className="top-1/2 countDiv relative before:content-none before:absolute before:bottom-[-30px] before:left-0 before:w-[100%] before:h-[35px]
                              before:bg-[#ff0] before:text-[#333] before:text-[0.35em] before:leading-[35px] before:font-light w-[3.125rem] h-[3.125rem] sm:w-[6.25rem] sm:h-[6.25rem] 
                              leading-[3.125rem] sm:leading-[100px] text-center bg-[#333] text-[#fff] my-0 mx-[15px] text-[2em] sm:text-[3em] font-medium" id="second">NA</div>
                </div>
                </div>
            </div>
        </div>
    </section>
  )
}
document.addEventListener('DOMContentLoaded', function() {
    setInterval(function(){
        finalDay();
    }, 1000)
});

export default Countdown