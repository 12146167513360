import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo  from '../../assets/images/logo.jpg'
import { FaBars, FaWindowClose } from 'react-icons/fa'
import Sidebar from '../Sidebar/Sidebar'
import { useEffect } from 'react'

function Nav() {
  const [open, setOpen] = useState(false);
  let Links = [
    {name : "Despre noi", link : "/"},
    {name : "Scoala de Ski&Snowboard", link : "/school"},
    {name : "Centru de inchirieri", link : "/renting"},
    {name : "Partii", link : "/slopes"},
    {name : "Contact", link : "/contact"},
  ];
  
          
  return (
    <div className='z-10 w-screen fixed top-0 left-0'>
      <div className='md:flex items-center justify-between bg-gradient-to-b from-white py-4 md:px-10 px-7 flex flex-wrap'>
        <div className='hidden lg:flex-1 lg:flex  justify-between items-center'>
          <Link to='/' className="rounded-full w-12 h-12 overflow-hidden relative" rel="nofollow">
            <img src={logo} class="rounded-full h-12" alt="RancaNDY" />
          </Link>
        </div> 
        <Sidebar/>
        <div className="hidden lg:flex lg:items-center lg:w-auto w-screen">
          <ul className="lg:flex items-center justify-between text-base text-gray-700 pt-4 lg:pt-0">
            {
              Links.map((link, key) => {
                if(link.name=="Scoala de Ski&Snowboard"){
                  return(
                    <li class="relative group">
                      <button class="lg:p-4 py-3 px-0 block">
                          <span className=" uppercase text-[1.2rem] tracking-[2px]">{link.name}</span>
                      </button>
                      <div class="absolute z-10 hidden bg-grey-200 group-hover:block w-full">
                          <div class="px-2 pt-2 pb-4 bg-white shadow-lg">
                            <div className="flex flex-col justify-center px-[2rem] uppercase text-[1.2rem] tracking-[2px]">
                              <Link to="school" className="flex justify-center mb-[1rem] border-b-2 border-transparent hover:border-main-color">Lectii</Link>
                              <Link to="team" className="flex justify-center mb-[1rem] border-b-2 border-transparent hover:border-main-color">Echipa noastra</Link>
                            </div>
                          </div>
                      </div>
                    </li>  
                  )
                }
                return(
                  <li key={link.name}>
                      <Link to={link.link} className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-main-color  uppercase
                                                      text-[1.2rem] tracking-[2px]">{link.name}</Link>
                  </li>
                )
              })
            }

          </ul>
        </div>
        
      </div>
    </div>
  )
}

export default Nav