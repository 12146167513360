import React from 'react'
import { Link } from 'react-router-dom'
import './Hexagone.css'
function HexagoneGrid({items}) {
  return (
    <section className="w-screen flex justify-center mt-[6rem] lg:mt-[10rem] lg:mb-[5rem]">
        <div class="container justify-center">
            <div class="row-hexa flex flex-wrap overflow-hidden hexa-slider h-full" id="hexa-grid">
                {items.map((item, idx) => {
                    return(
                        <div className={`col-hexa relative after:content-[''] after:block after:pb-[86.602%] ${idx % 3 == 0 ? "hexa-hover": ""}`}
                              key={idx}>
                            <div class="hexa absolute w-[96%] pb-[110.851%] my-0 mx-[2%] overflow-hidden">
                                <div class="hexa-link group absolute visible block w-full h-full text-center text-[#FFF] overflow-hidden">
                                    <img class="hexa-img absolute left-[-100%] right-[-100%] w-full my-0 mx-auto h-full" src={item.img} alt="" />
                                    <div class="hexa-content absolute visible w-full h-full top-0 left-0 flex flex-col justify-center items-center
                                                group-hover:bg-[#3f51b5cc] group-focus:bg-[#3f51b5cc]">
                                        <h2 className="title mb-[10px] text-[#fff] relative py-0 px-[20px] visible font-bold z-[3]">
                                            {item.title}
                                        </h2>
                                        <p class="description text-main-color relative py-0 px-[20px] visible font-bold z-[3]">
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            
            
        </div>
        </div> 
    </section>
  )
}

export default HexagoneGrid