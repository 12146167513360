import React from 'react'

function RentingCard({img, title, description}) {
  return (
    <div className="flex flex-col mx-[2rem] w-[12rem] justify-center items-center">
        <img src={img}
             className="h-[7rem] w-[7rem] rounded-full"/>
        <div className="font-bold  text-[1.5em] text-white">{title}</div>
        <div className="font-light mt-2 text-white">{description}</div>
    </div>
  )
}

export default RentingCard