import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import { SidebarData } from "./SidebarData";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Navigation = () => (
  <motion.ul variants={variants}
             className="p-[25px] absolute top-[100px] max-w-sm">
    {SidebarData.map((item, idx) => (
      <MenuItem item={item} i={idx} key={idx} />
    ))}
  </motion.ul>
);

const itemIds = [0, 1, 2, 3, 4];