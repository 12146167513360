import React from 'react'
import p1 from '../../assets/images/43.jpeg'
import p2 from '../../assets/images/44.jpeg'
import './Contact.scss'
import $ from 'jquery'; 
import '../Footer/Footer.css'
function ContactSection() {
  return (
    <div class="min-h-screen w-screen mb-[3rem] mt-[2rem]">
        <div class="px-0 md:px-[2rem] py-[2rem] md:p-[5rem] min-h-screen w-full grid grid-cols-1 md:grid-cols-2">
            <div className="flex flex-col md:flex-row w-full h-screen mt-[2rem]">
                <div class="app h-2/3 md:h-full relative w-full md:w-5/6 overflow-hidden rounded-2xl">
                    <div class="app__bgimg">
                        <div class="app__bgimg-image app__bgimg-image--1"/>
                        <div class="app__bgimg-image app__bgimg-image--2"/>
                    </div>
                    <div class="app__text app__text--2 left-[1rem] md:left-[4rem] lg:left-[5rem]">
                        <div class="app__text-line app__text-line--4 text-main-color uppercase text-[2.5em] md:text-[2.2em] lg:text-[3.125em] font-bold">Centru NDY</div>
                        <div class="app__text-line app__text-line--3 text-[2rem] lg:text-[2.5rem] font-normal text-yellow-400">#Calitate</div>
                        <div class="app__text-line app__text-line--2 uppercase text-blue-600 mt-[10px] text-[1rem] font-medium">#Profesionalism</div>
                        <div class="app__text-line app__text-line--1"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/opus-attachment.png" alt="" /></div>
                    </div>
                    
                    <div class="app__text app__text--1 left-[1rem] md:left-[4rem] lg:left-[5rem]">
                        <div class="app__text-line app__text-line--4 text-white uppercase  text-[2.5em] md:text-[2.2em] lg:text-[3.125em] font-bold">Echipa NDY</div>
                        <div class="app__text-line app__text-line--3 text-[2rem] lg:text-[2.5rem] font-light text-yellow-400">#Va asteapta</div>
                        <div class="app__text-line app__text-line--2 uppercase text-green-400 mt-[10px] text-[0.875rem] font-medium">#snowboard & ski</div>
                        <div class="app__text-line app__text-line--1"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/opus-attachment.png" alt="" /></div>
                    </div>
                </div>
                <div class="pages h-[3rem] md:h-full relative ml-[2rem]">
                    <ul class='w-full pages__list align-middle absolute top-1/3 flex flex-row md:flex-col'>
                        <li data-target='1' class='pages__item pages__item--1 page__item-active'></li>
                        <li data-target='2' class='pages__item pages__item--2'></li>
                    </ul>
                </div>
            </div>
            <div className="flex flex-col w-full h-screen justify-center mt-[-15rem] md:mt-0">
                <div className="justify-center flex mb-[1.5rem] text-[3em] font-light text-gray-700">
                    Ai
                    <span className="font-bold ml-[0.6rem]">intrebari?</span>
                </div>
                <div className="h-[1px] bg-gray-700 mb-[2rem]"/>
                <div className="flex flex-row mb-[3rem] justify-center">
                    <i className="fa fa-phone fa-2x text-green-500 hover:text-green-700"/>
                    <div className="ml-[0.4rem] cursor-pointer font-normal hover:font-bold">0723 920 452</div>
                </div>
                <div className="flex flex-row mb-[3rem] justify-center">
                    <i className="fa fa-paper-plane fa-2x text-blue-700"/>
                    <div className="ml-[0.4rem] font-normal hover:font-bold"> rezervari@rancandy.ro</div>
                </div>
                <div className="flex flex-row mb-[3rem] justify-center">
                    <i className="fa fa-tree fa-2x text-green-700"/>
                    <div className="ml-[0.4rem] font-light hover:font-bold uppercase"> 
                        Strada Cornesu Mare nr.180A,<br/>
                                            Rânca 215300
                    </div>
                </div>
                <div className="flex flex-row mb-[3rem] justify-center">
                    <i className="fa fa-map-pin fa-2x text-main-color"/>
                    <div className="ml-[0.4rem] font-normal hover:font-bold"> 
                        La baza partiei M1
                    </div>
                </div>
                <div className="h-[1px] bg-gray-700 mb-[2rem]"/>
                <div className="flex justify-center font-bold italic mb-[2rem] text-[1.3em] md:text-[1.7em] text-gray-700">Ne gasiti si pe retelele de socializare!</div>
                <div className="text-center justify-center flex mb-[1.2rem] md:mb-0">
                    <a class="footerIcon" 
                    href="https://www.facebook.com/instructorSki1/"
                    style={{ transition: '0.3s linear'}}>
                        <i class="fa fa-facebook-f leading-[3.75rem] text-[1.625rem] hover:scale-[1.2]"
                        style={{ transition: '0.3s linear'}}/>
                    </a>

                    <a class="footerIcon" 
                    href="https://www.instagram.com/scoala.ranca.ndy/"
                    style={{ transition: '0.3s linear'}}>
                        <i class="fa fa-instagram leading-[3.75rem] text-[1.625rem] hover:scale-[1.2]"
                        style={{ transition: '0.3s linear'}}/>
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
  
}

document.addEventListener('DOMContentLoaded', function() {
    setInterval(function(){
    const $app = $('.app');
	const $img = $('.app__img');
	const $pageNav1 = $('.pages__item--1');
	const $pageNav2 = $('.pages__item--2');
	let animation = true;
	let curSlide = 1;
	let scrolledUp, nextSlide;
	
	let pagination = function(slide, target) {
		animation = true;
		if (target === undefined) {
			nextSlide = scrolledUp ? slide - 1 : slide + 1;
		} else {
			nextSlide = target;
		}
		
		$('.pages__item--' + nextSlide).addClass('page__item-active');
		$('.pages__item--' + slide).removeClass('page__item-active');
		
		$app.toggleClass('active');
		setTimeout(function() {
			animation = false;
		}, 3000)
	}


	setTimeout(function() {
		$app.addClass('initial');
	}, 1500);

	setTimeout(function() {
		animation = false;
	}, 4500);
	


	$(document).on("click", ".pages__item:not(.page__item-active)", function() {
		if (animation) return;
		let target = +$(this).attr('data-target');
		pagination(curSlide, target);
		curSlide = target;
	});
}, 1000)});

export default ContactSection
