import React from 'react'
import InstructorCard from './InstructorCard'
import doky from '../../assets/images/22.jpeg'
import petrica from '../../assets/images/23.jpeg'
import tibi from '../../assets/images/24.jpeg'
import razvan from '../../assets/images/25.jpeg'
import ovi from '../../assets/images/26.jpeg'
import bianca from '../../assets/images/27.jpeg'
import cristiana from '../../assets/images/28.jpeg'
import ilie from '../../assets/images/29.jpeg'
import radu from '../../assets/images/123.jpeg'

const data = [
    {
        name: 'Doky',
        title: 'Monitor ski&snowboard, 27 ani',
        facebook: 'https://www.facebook.com/doky.d.doky',
        instagram: 'https://www.instagram.com/do_ky01/',
        img: doky,
    },
    {
        name: 'Petrica',
        title: 'Monitor ski, 25 ani',
        facebook: 'https://www.facebook.com/buculea.petryka',
        instagram: 'https://www.instagram.com/petricabuculea/',
        img: tibi,
    },
    {
        name: 'Tibi',
        title: 'Monitor ski, 28 ani',
        facebook: 'https://www.facebook.com/stoian.tibi',
        instagram: 'https://www.instagram.com/stoiantibi/',
        img: petrica,
    },
    {
        name: 'Razvan',
        title: 'Monitor ski, 23 ani',
        facebook: 'https://www.facebook.com/razvan.surupaceanu1',
        instagram: 'https://www.instagram.com/razvan.drk19/',
        img: razvan,
    },
    {
        name: 'Ovidiu',
        title: 'Monitor ski, 25 ani',
        facebook: 'https://www.facebook.com/profile.php?id=100004185188731',
        instagram: 'https://www.instagram.com/zamfira.ovidiu/',
        img: ovi,
    },
    {
        name: 'Bianca',
        title: 'Monitor ski, 23 ani',
        facebook: 'https://www.facebook.com/biancabuduran',
        instagram: 'https://www.instagram.com/biancabuduran/',
        img: bianca,
    },
    {
        name: 'Cristiana',
        title: 'Monitor ski, 27 ani',
        img: cristiana,
    },
    {
        name: 'Radu',
        title: 'Monitor ski, 26 ani',
        facebook: 'https://www.facebook.com/Radu.22.Rapid',
        instagram: 'https://www.instagram.com/radu.deaconescu/',
        img: radu,
    },
    {
        name: 'Ilie',
        title: 'Manager, 25 ani',
        facebook: 'https://www.facebook.com/ilieN1234',
        instagram: 'https://www.instagram.com/ilie__nedu/',
        img: ilie,
    },
]
function Team() {
    return(
        <div class="max-w-screen flex justify-center">
            <div class="flex flex-col pt-[8rem] md:px-[6rem] max-w-[1140px] justify-center">
                <div class="text-center">
                    <h2 class="section-heading uppercase text-[2.5em]">Echipa noastra</h2>
                    <h3 class="section-subheading font-light text-slate-500">Este nerabdatoare sa te cunoasca!</h3>
                </div>
                <div class="flex flex-wrap justify-center mt-[5rem]">
                    {data.map((item, idx) => {
                        return (
                            <InstructorCard name={item.name}
                                            title={item.title}
                                            facebook={item.facebook}
                                            instagram={item.instagram}
                                            img={item.img}
                                            key={idx}
                                            />
                        )
                    })}
                    
                    
                </div>
            </div>
        </div>
    )
}

export default Team