import React from 'react'
import './School1.css'
function School1({title, subtitle, description1, description2, img}) {
  return (
    <div className="w-screen h-screen top-0 left-0 overflow-hidden">
      <div class="school1Body relative top-[7rem] h-screen md:h-4/5 lg:h-4/5 grid grid-cols-1  md:grid-cols-2 lg:grid-cols-2 bg-no-repeat bg-cover bg-school"
           style={{textShadow:'0px 1px 1px gray'}} >   
        <div className="school1Text px-9 mx-4 lg:mx-20 flex flex-col">
          <h3 className="lg:pt-[7rem] p-[2rem] flex justify-center uppercase text-4xl font-bold leading-[1em] opacity-0 text-white">{title}</h3>
          <p className="pb-[2rem] flex justify-center opacity-0 text-gray-300">{subtitle}</p>
          <p className="font-normal text-[0.9em] leading-[1.75em] text-lg pb-[1rem] flex justify-center opacity-0">{description1}</p>
          <p className="font-normal text-[0.9em] leading-[1.75em] pb-[2rem] flex justify-center opacity-0">{description2}</p>
        </div>
      </div>
    </div>
  )
}

export default School1
/*<img src={img} alt="scoala de ski" 
className="max-w-[100%] align-top"/>*/