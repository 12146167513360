import React from 'react'
import About from './About'
import './Footer.css'
import Menu from './Menu'
const data = [
    {title : "Despre noi", link : "/"},
    {title : "Scoala de Ski&Snowboard", link : "/school"},
    {title : "Centru de inchirieri", link : "/renting"},
    {title : "Partii", link : "/slopes"},
    {title : "Contact", link : "/contact"},
]
function Footer() {
  return (
    <div className="bg-black">
        <div className="flex flex-col">
            <div className="flex flex-wrap justify-center mt-4">
                   <About/>
                   <Menu menuList={data}/>
            </div>
            <div className="text-center scale-75">
                <a class="footerIcon" 
                   href="https://www.facebook.com/instructorSki1/"
                   style={{ transition: '0.3s linear'}}>
                    <i class="fa fa-facebook-f leading-[3.75rem] text-[1.625rem] hover:scale-[1.2] bg-black"
                       style={{ transition: '0.3s linear'}}/>
                </a>

                <a class="footerIcon" 
                   href="https://www.instagram.com/scoala.ranca.ndy/"
                   style={{ transition: '0.3s linear'}}>
                    <i class="fa fa-instagram leading-[3.75rem] text-[1.625rem] hover:scale-[1.2]"
                       style={{ transition: '0.3s linear'}}/>
                </a>
            </div>
            <p className="text-lg md:text-xl text-main-color font-light justify-center flex">
                © 2022 Bianca Buduran™. All Rights Reserved.
            </p>
        </div>
    </div>
  )
}

export default Footer