import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  date: "",
  error: "",
};

const dateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    chooseDay: (state, { payload }) => {
      state.date = payload; 
    },
    dayReset: (state) => {
      state = initialState
    },
  },
});

const { reducer, actions } = dateSlice;

export const { chooseDay, dayReset } = actions;

export default reducer;