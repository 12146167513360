import React, { useRef, useState } from "react";
import p1 from '../../assets/images/photo34.jpeg'
import p2 from '../../assets/images/89.jpeg'
import p3 from '../../assets/images/90.jpeg'
import p4 from '../../assets/images/111.jpeg'
import p5 from '../../assets/images/74.jpeg'
import p6 from '../../assets/images/73.jpeg'
import p7 from '../../assets/images/12.jpeg'
import p8 from '../../assets/images/120.jpeg'
import p9 from '../../assets/images/30.jpeg'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "swiper/components/pagination"
import "swiper/components/navigation"


// import Swiper core and required modules
import SwiperCore, {
    Autoplay,Navigation,Pagination
  } from 'swiper';
  
  // install Swiper modules
  SwiperCore.use([Autoplay, Pagination, Navigation]);
const photos = [
  {
    photo: p4,
    alt: "Nocturna Partia M1 Ranca"
  },
  {
    photo: p2,
    alt: "Domeniul schiabil Ranca"
  },
  {
    photo: p3,
    alt: "Partie telescaun Ranca"
  },
  {
    photo: p1,
    alt: "Drum intrare in Ranca"
  },
  {
    photo: p5,
    alt: "Bucura-te de echipamentele de ski inchiriate de la centrul NDY"
  },
  {
    photo: p6,
    alt: ""
  },
  {
    photo: p7,
    alt: "Baza partiei M1, langa punctul Salvamont"
  },
  {
    photo: p8,
    alt: "Harta domeniul schiabil Ranca"
  },
  {
    photo: p9,
    alt: "Distractie pe partia M1 Ranca"
  }
]
export default function Gallery() {
  return (
    <div className="w-screen h-[18rem] md:h-[30rem] mt-[2rem] md:mt-[7rem] mb-[3rem]">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper w-3/4 h-full items-center flex justify-center"
      >
          {
              photos.map((item, idx) => {
                  return(
                    <SwiperSlide key = {idx}>
                        <img src={item.photo}
                             className="block w-full h-full object-contain"
                             alt={item.alt}
                             title={item.alt}/>
                    </SwiperSlide>
                  )
              })
          }
    
      </Swiper>
    </div>
  );
}
