import React from 'react'
import { Link } from 'react-router-dom'
import { FaCoffee, FaHome, FaSkiing, FaSnowboarding } from 'react-icons/fa'
import './Services.css'
function ServiceCard({card}) {
  function chooseIcon(service){
    switch(service) {
        case 'ski' :
            return( <div className="flex justify-center align-middle h-full"><FaSkiing className="absolute w-[50px] h-[50px] top-1/4"/></div> )
        case 'snowboarding' :
            return( <div className="flex justify-center align-middle h-full"><FaSnowboarding className="absolute w-[50px] h-[50px] top-1/4"/></div> )
        case 'home':
            return( <div className="flex justify-center align-middle h-full"><FaHome className="absolute w-[50px] h-[50px] top-1/4"/></div>  )
        case 'coffee':
            return( <div className="flex justify-center align-middle h-full"><FaCoffee className="absolute w-[50px] h-[50px] top-1/4"/></div> )
    }
} 
  return (

        <div className="max-w-lg w-full md:w-2/5 mx-[1rem] md:mx-[4rem] lg:mx-[4rem] mt-[5rem]">
            <div className="serviceContent relative transition-all duration-[1.5s] ease text-center group
                             flex flex-col">
                <div className="img-box group-hover:border-b-[3px] group-hover:border-solid group-hover:border-b-main-color">
                    <div className="text-black">
                        <img src={card.img} 
                             className="w-full object-cover h-[300px]"/>
                    </div>
                </div>
                <div className="lowerContent relative border-[1px] border-solid border-[#e5e5e5] h-[200px]">
                    <div className="icon-box group-hover:rotate-[360deg] relative inline-block top-[-50px] w-[100px] h-[100px] leading-[100px] bg-[#fff]
                                    rounded-[50px] transition-all duration-[0.5s] ease">
                        { chooseIcon(card.faClass) }
                    </div>
                    <h3 className="mt-[-50px]">
                        <Link className="serviceTitle text-black leading-[0.1rem] hover:text-main-color text-[1.75em]"
                              to={card.link}>
                            {card.name}
                        </Link>
                    </h3>
                    <div className="text-[0.875em] mt-[1rem] mx-[1rem]">
                        {card.description} 
                    </div>
                </div>
            </div>
        </div>
 
  )
}

export default ServiceCard