import React from 'react'
import { useState } from 'react'
import { dailyCalendar } from '../../sharedCalendarFunctions'
import CalendarHeader from './CalendarHeader'
import DailyCalendar from './DailyCalendar'
function DailyDashboard() {
  const [currentDaySlots, setCurrentDaySlots] = useState(dailyCalendar)
  return (
    <React.Fragment>
        <div className="h-screen flex flex-col py-14  lg:mx-20 lg:py-16 mt-[3.5rem]">
            <div className="flex flex-1">
                <DailyCalendar slots={currentDaySlots}/>
            </div>
        </div>
    </React.Fragment>
  )
}

export default DailyDashboard