import './App.css';
import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Circle';
import SignUpFirstStep from './components/RegisterLogIn/SignUpFirstStep';
import Progress from './components/Progress';
import SignUpSecondStep from './components/RegisterLogIn/SignUpSecondStep';
import Dashboard from './components/Dashboard';
import BookingDashboard from './components/MonthlyCalendar/BookingDashboard';
import ContextWrapper from './context/ContextWrapper';
import DailyDashboard from './components/DailyCalendar/DailyDashboard';
import Home from './pages/Home';
import Booking from './pages/Booking';
import Circle from './components/Circle';
import School1 from './components/School/School1';
import Sidebar from './components/Sidebar/Sidebar';
import RentingCenter from './pages/RentingCenter';
import SlopeHero from './components/Slopes/SlopeHero';
import Login from './pages/Login';
import Profile from './components/UserComponents/Profile';
import UserDashboard from './components/UserComponents/UserDashboard';
import SchoolInfo from './pages/SchoolInfo';
import Slopes from './pages/Slopes';
import Team from './components/School/Team';
import Footer from './components/Footer/Footer';
import Contact from './pages/Contact';
import Camp from './pages/Camp';
import AfterLogin from './components/AfterLogin';
import CampPrivateSection from './components/Camps/CampPrivateSection';
import UserEnrollments from './components/UserComponents/UserEnrollments';
import AdminDashboard from './components/AdminComponents/AdminDashboard';
import AddCamp from './components/AdminComponents/AddCamp';
import DeleteCamp from './components/AdminComponents/DeleteCamp';
import ModifyCamp from './components/AdminComponents/ModifyCamp';
import CampCardModify from './components/AdminComponents/CampCardModify';
import ParticipantsPanel from './components/AdminComponents/ParticipantsPanel';
import SeeEnrollments from './components/AdminComponents/SeeEnrollments';
import SeeCampEnrollments from './components/AdminComponents/SeeCampEnrollments';
import MonthlyCalendar from './components/AdminComponents/AdminMonthlyCalendar/MonthlyCalendar';
import AdminDailyDashboard from './components/AdminComponents/AdminDailyCalendar/AdminDailyDashboard';
import ScrollToTop from './pages/ScrollToTop';
function App() {
  const [user, setUser] = useState({});

  const updateUser = (data) => {
    setUser((prevUser) => ({ ...prevUser, ...data }));
  };

  const resetUser = () => {
    setUser({});
  };

  return (
    <BrowserRouter>
      <Navbar/>
      <ScrollToTop/>
      <Routes>
        <Route path="/school" element={<SchoolInfo/>}/>
        <Route path="/renting" element={<RentingCenter/>}/>
        <Route path="/slopes" element={<Slopes/>}/>
        <Route path="/" element={<Home/>}/>
        <Route path="/team" element={<Team/>}/> 
        <Route path="contact" element={<Contact/>}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
