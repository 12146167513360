import React from 'react'
import '../AfterLogin.css'
function CenterDescription({info}) {
  return (
    <div className="w-screen mt-[2rem] md:mt-[-18rem]">
        <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="w-full text-center justify-center flex flex-col px-[1rem] md:px-[5rem]">
                <div className="mb-[3em] text-black font-normal text-[2rem] md:text-[2.5rem]  text-center">
                    {info.title}
                    <span className="mx-[0.4rem] text-main-color">NDY</span>
                    !
                </div>
                <div className="mx-[1.5rem] md:mx-[3rem] text-[1.2rem]">
                    {info.description}
                </div>
                <div className="redirectTitle text-[#00008B] font-bold mt-[2rem] text-[2.5rem]">
                    PROGRAM: 
                    <span className="text-main-color mx-[1rem]">10-19</span>
                </div>
            </div>
            <div className="w-full  h-[20rem] md:h-screen flex flex-col justify-center px-[1.5rem] ">
                <div className="h-2/3 relative"> 
                    <img src = {info.img}
                         className="absolute top-0 left-0 h-full w-full md:w-4/5 rounded-xl  shadow-xl backdrop-blur-xl hover:-translate-y-2 hover:shadow-2xl transition-all duration-300"/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CenterDescription