import React from 'react'
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import loginReducer from '../reduxSlices/loginSlice';
import userReducer from '../reduxSlices/userSlice';
import bookingsReducer from '../reduxSlices/bookingsSlice';
import popUpReducer from '../reduxSlices/popUpSlice'
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import dateReducer from '../reduxSlices/dateSlice'
import storage from 'redux-persist/lib/storage' // defaults to localStorage 
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
  })
const persistConfig = {
    key: 'root',
    version: 1,
    storage: storage,
    stateReconciler: autoMergeLevel2,
}

const userPersistConfig = {
    key: 'user',
    storage: storage,
}


const bookingsPersistConfig = {
    key: 'bookings',
    storage: storage,
}


const loginPersistConfig = {
    key: 'login',
    storage: storage,
}


const rootReducer = combineReducers({ 
    user: persistReducer(userPersistConfig, userReducer),
    login: persistReducer(loginPersistConfig, loginReducer),
    popUp: popUpReducer,
    bookings: persistReducer(bookingsPersistConfig, bookingsReducer),
  })
/*const rootReducer = (state, action) => {
    if (action.type === 'userReset') {
      return mainReducer(undefined, action)
    }
  
    return mainReducer(state, action)
}*/
const persistedReducer = persistReducer(persistConfig, rootReducer)


const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  })

let persistor = persistStore(store);

export {persistor, store}
  

