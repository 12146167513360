import React from 'react'
import '../AfterLogin.css'
import p1 from '../../assets/images/16.jpeg'
const data = {
  paragraph1: '🗻Situata la o altitudine de 1600m, statiunea Ranca iti vine in intampinare cu 6 partii si peisaje care iti taie rasuflarea.',
  paragraph2: '🏂⛷️Partiile sunt deservite de instalatii pe cablu. In plus, M1 beneficiaza si de nocturna.',
  paragraph3: '🏂⛷️Poti practica si schi fond, in imediata apropiere a partiilor, fara risc de avalansa.',
}
function SlopeMap() {
  return (
    <div className="w-screen mt-[6rem]">
      <div className="flex flex-col">
          <div className="redirectTitle text-main-color text-[2em] md:text-[3em] lg:text-[3.5rem] uppercase text-center mb-[1rem] md:mb-[3rem]">
              Domeniul schiabil Ranca
          </div>
          <div className="flex flex-wrap p-0 md:p-[4rem] w-screen">
            <div className="p-[4rem] w-screen md:w-1/2 text-[1.8em] flex flex-col font-normal redirectTitle ">
              <div className="mb-[2rem] md:mb-[1.2rem]">{data.paragraph1}</div>
              <div className="mb-[2rem] md:mb-[1.2rem]">{data.paragraph2}</div>
              <div>{data.paragraph3}</div>
            </div>
            <div className="mb-[3rem] md:mb-0 rounded-xl  shadow-xl backdrop-blur-xl hover:-translate-y-2 hover:shadow-2xl transition-all duration-300 w-screen md:w-1/2 h-[30rem]">
              <img src={p1}
                   className="rounded-xl w-full h-full"
                   alt="Harta Partii Ranca"
                   title="Harta Partii Ranca"/>
              
            </div>
          </div>
         
      </div>
    </div>
  )
}

export default SlopeMap