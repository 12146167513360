import React from 'react'
import './RentingHero.css'
function RentingHero() {
  return (
    <div className="w-screen h-screen">
      <section className="w-full h-full overflow-hidden relative bg-cover bg-rentingCenter rentingHeroSection">
        <div class="absolute top-0 left-0 z-[8] w-full h-full">
          <div className="">
            <h2 className="text-[#00008B] uppercase font-semibold text-[2rem] md:text-[4rem]  text-center mb-[3rem]  mt-[13rem]">
              Centrul de inchirieri
              <span className="uppercase ml-[0.3em] text-main-color font-extrabold">Ndy</span>
            </h2>
          </div>
        </div>
        <div class="skew-image absolute top-0 left-0 bottom-0 right-0">
          <div className="absolute top-0 bottom-0 overflow-hidden rentingHeroDiv rentingHeroDiv1
                          before:bg-rentingCenter before:bg-cover before:backdrop-blur-[2px]"></div>
          <div className="absolute top-0 bottom-0 overflow-hidden rentingHeroDiv rentingHeroDiv2 
                          before:bg-rentingCenter before:bg-cover before:backdrop-blur-[5px] before:backdrop-contrast-[125%] before:backdrop-grayscale"></div>
        </div>
      </section>
  </div>
  )
}

export default RentingHero