import React from 'react'
import { Icon } from '@iconify/react';

function About() {
  return (
    <div className="flex flex-col font-medium">
        <h2 className="flex text-white pb-2 text-[1.5em]">
            <span className="icon flex items-center justify-center text-main-color">
                <Icon icon="ion:logo-ionic" />
            </span>
            About 
        </h2>
        <div className="flex flex-row text-[rgba(255,255,255,.3)] pt-1">
            <Icon icon="ion:pin"  className="icon"/>
            <span className="font-light">
                Strada Cornesu Mare 
                <br/>
                nr.180A, Rânca 215300
            </span>
        </div>
        <div className="flex flex-row text-[rgba(255,255,255,.3)] pt-1">
            <Icon icon="ion:call" className="icon"/>
            <span className="font-light">
                0723 920 452
            </span>
        </div>
        <div className="flex flex-row text-[rgba(255,255,255,.3)] pt-1">
            <Icon icon="fluent:mail-add-16-filled" className="icon"/>
            <span className="font-light">
                rezervari@rancandy.ro
            </span>
        </div>
    </div>
  )
}

export default About