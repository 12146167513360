import React from 'react'
import RentingCard from '../RentingCenter/RentingCard'
import p1 from '../../assets/images/115.jpeg'
import p2 from '../../assets/images/113.jpeg'
import p3 from '../../assets/images/116.jpeg'
import p4 from '../../assets/images/114.jpeg'
const data = [
    {
        title: '2 teleschiuri',
        img: p1,
    },
    {
        title: '3 telescaune',
        img: p2,
    },
    {
        title: '650m nocturna',
        img: p3,
    },
    {
        title: '1 singur skipass',
        img:p4,
    },
]
function SlopesBanner() {
  return (
        <div className="w-screen h-[50rem] md:h-[15rem] relative bg-main-color mb-[3rem] md:mb-0">
                <div className="flex flex-wrap justify-center align-middle h-full">
                    {
                        data.map((item, idx) => {
                            return(
                                <RentingCard title={item.title}
                                             img={item.img}
                                             key={idx}/>
                            )
                        })
                    }
                </div>
        </div>
  )
}

export default SlopesBanner