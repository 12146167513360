import React from 'react'
import UserBookings from './UserBookings'
import UserEnrollments from './UserEnrollments'
function UserDashboard() {
  return (
    <div>
      <UserBookings/>
      <div id="divider" className="my-[3rem] rounded-full ring-2 ring-gray-200 lg:w-1/2 lg:mx-auto "></div>
      
    </div>
  )
}

export default UserDashboard