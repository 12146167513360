import * as React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

export const MenuItem = ({ item, i }) => {
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      className="list-none mb-[20px] flex items-center cursor-pointer"
    >
      <div className="icon-placeholder w-[30px] h-[30px] rounded-full flex-grow-[40px] mr-[20px] border-solid border-2 border-main-color bg-main-color"/>
      <div className="text-placeholder w-[200px] hover:border-b-2 flex-1 hover:border-b-solid  hover:border-b-main-color">
          <Link to={item.path}>
            {item.title}
          </Link>
      </div>
    </motion.li>
  );
};