import React from 'react'

function School2Card({img, title, description}) {
  return (
    <div className="flex flex-col mx-2 max-w-sm mb-10 justify-center items-center">
        <img src={img}
             className="h-[7rem] w-[7rem] rounded-full"/>
        <div className="text-center font-bold mt-[4rem] text-[1.5em]">{title}</div>
        <div className="text-center font-light mt-2">{description}</div>
    </div>
  )
}

export default School2Card