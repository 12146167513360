import dayjs from 'dayjs'

export function getMonth(month = dayjs().month()){
    const year = dayjs().year();
    const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
    let currentMonthCount = 0 - firstDayOfTheMonth;
    const daysMatrix = new Array(5).fill([]).map(() => {
      return new Array(7).fill(null).map(() => {
        currentMonthCount++;
        return dayjs(new Date(year, month, currentMonthCount));
      });
    });
  return daysMatrix;
}

export const instructors = ["","Bianca", "Tibi", "Ovidiu", "Doky", "Cristiana", "Petrica", "Razvan"]
export const hours = ["", "10-11", "11-12", "12-13", "13-14", "14-15", "15-16", "16-17", "17-18"]
const dailyCalendar = new Array(9).fill([]).map(() => {
  return new Array(8).fill(null)

})
dailyCalendar[0]=instructors
dailyCalendar.map((item, index) => (item[0] = hours[index]))
export {dailyCalendar}

function computeDailyCalendarColours(){
  let dailyCalendarColours = new Array(9).fill([]).map(() => {
    return new Array(8).fill(null).map(() => {
      return '#2EFF2E'
    })
  })
  for (let i = 0; i < 8; i++) {
    dailyCalendarColours[0][i] = 'white'
  }
  dailyCalendarColours.map((item, index) => (item[0] = 'white'))
  return dailyCalendarColours
}

export {computeDailyCalendarColours}

export const instructorDictionary = {
  'Bianca' : 1,
  'Tibi' : 2,
  'Ovidiu' : 3,
  'Doky' : 4,
  'Cristiana' : 5,
  'Petrica' : 6,
  'Razvan' : 7,
}
export const hoursDictionary = {
  "10-11" : 1, 
  "11-12" : 2,
  "12-13" : 3,
  "13-14" : 4,
  "14-15" : 5,
  "15-16" : 6,
  "16-17" : 7,
  "17-18" : 8,
}