import React from 'react'
import './MoreInfo.css'
import p1 from '../../assets/images/31.jpeg'
import p2 from '../../assets/images/32.jpeg'
import p3 from '../../assets/images/34.jpeg'
import p4 from '../../assets/images/35.jpeg'
import p5 from '../../assets/images/33.jpeg'
const data = [
    {
        heading:'Scoala de ski&snowboard',
        subheading:'Categorie de varsta: copii',
        description:'Vrei ca micutul sa adore ski-ul / snowboard-ul? Instructorii nostri iti sar in ajutor. Varsta minima recomandata este 3 ani.',
        img:p2,
        alt:"Scoala de ski&snowboard pentru copii Ranca",
    },
    {
        heading:'Scoala de ski&snowboard',
        subheading:'Categorie de varsta: adulti',
        description:'Vrei sa inveti un sport de iarna si nu stii de unde sa incepi sau doresti sa te perfectionezi? Contacteaza echipa NDY, fa-ti o programare si ne vedem pe partie!',
        img:p3,
        alt:"Scoala de ski&snowboard pentru adulti Ranca",
    },
    {
        heading:'Centru de inchirieri',
        subheading:'Branduri de top: Burton, Fischer, Atomic, Salomon',
        description:'Vacanta inseamna distractie. Asa ca nu te ingrijora in legatura cu echipamentul. Ne ocupam noi de asta. Ne gasesti la baza partiei M1.',
        img:p4,
        alt:"Centru de inchirieri Ranca NDY",
    },
    {
        heading:'Bar NDY',
        subheading:'Direct pe partie',
        description:'Vrei sa iei o pauza si sa savurezi un pahar de vin fiert sau un shot de Tatratea si sa te bucuri de vibe-ul de munte? Barul NDY iti vine in intampinare.',
        img:p5,
        alt:"Bar NDY la baza partiei M1 Ranca",
    }

]

function MoreInfo() {
  return (
    <section className="w-screen min-h-screen py-[6.25rem]" id="about">
        <div className="relative w-screen min-h-screen">
            <div class="before:content-['']
                        before:absolute
                        before:top-0
                        before:left-0
                        before:right-0
                        before:bottom-0
                        MoreInfoBackground
                        before:z-[1] 
                        absolute top-0 right-0 bottom-0 left-0 w-full overflow-hidden z-[-1]">
                <img src={p1}
                     className="absolute w-full h-full object-cover"/>
            </div>
            <div className="flex flex-col text-center">
                    <h2 className="uppercase text-[2.5rem] mb-[1rem]">Echipa NDY</h2>
                    <h3 className="text-gray-400 text-[1em] font-normal italic mb-[4.688rem] normal-case">Este aici sa te ajute.</h3>
                    <ul className="md:before:left-1/2
                                   before:absolute before:top-0 before:bottom-0 before:left-[40px] before:w-[2px] before:ml-[-1.5px] before:content-[''] before:bg-main-color 
                                   list-none relative p-0 timeline">
                        {data.map((item, idx) => {
                            return (
                                <li key={idx}
                                    className={`xl:min-h-[10.625rem]
                                                lg:min-h-[9.375rem]
                                                md:min-h-[6.25rem] md:mb-[6.25rem]
                                                relative min-h-[3.125rem] mb-[3.125rem] 
                                                ${idx%2 === 1 ? 'timeline-inverted' : ''}`}
                                               >
                                    <div className="timeline-image absolute z-[1] left-0 w-[5rem] h-[5rem] ml-0 text-center text-[#fff] border-[7px] 
                                                    solid border-main-color rounded-full bg-main-color
                                                    md:left-[50%] md:w-[6.25rem] md:h-[6.25rem] md:ml-[-3.125rem]
                                                    lg:w-[9.375rem] lg:h-[9.375rem] lg:ml-[-4.688rem]
                                                    xl:w-[10.625rem] xl:h-[10.625rem] xl:ml-[-5.3215rem]">
                                        <img className="w-full h-full object-cover rounded-full" src={item.img} alt={item.alt}/>
                                    </div>
                                    <div className="xl:p-[0_20px_20px_100px]
                                                    lg:pt-0 lg:pb-[20px] lg:px-[20px]
                                                    md:float-left md:w-[41%] md:padding-[0_20px_20px_30px] md:text-right
                                                    float-right p-[0_20px_0_100px] text-left
                                                    timeline-panel relative w-full">
                                        <div className="timeline-heading justify-center flex flex-col">
                                            <h4 className="mt-0 text-inherit font-bold justify-center flex text-[1.5em]">{item.heading}</h4>
                                            <h4 className="normal-case text-main-color text-[17px] font-bold justify-center flex">{item.subheading}</h4>
                                        </div>
                                        <div className="timeline-body">
                                            <p className="text-black font-medium mb-0">
                                                {item.description}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                        <li className="timeline-inverted mb-0">
                            <div className="timeline-image  absolute z-[1] left-0 w-[5rem] h-[5rem] ml-0 text-center text-[#fff] border-[7px] 
                                                    solid border-main-color rounded-full bg-main-color
                                                    md:left-[50%] md:w-[6.25rem] md:h-[6.25rem] md:ml-[-3.125rem]
                                                    lg:w-[9.375rem] lg:h-[9.375rem] lg:ml-[-4.688rem]
                                                    xl:w-[10.625rem] xl:h-[10.625rem] xl:ml-[-5.3215rem]">
                                <h4 className="text-[0.625em] leading-[0.875em] mt-[0.75em]
                                               md:text-[0.813em] md:leading-[1.125em] md:mt-[1em]
                                               lg:text-[1.125em] lg:leading-[1.625em] lg:mt-[1.875em]
                                               xl:mt-[40px]">
                                    Be Part
                                    <br/>Of Our
                                    <br/>Story!
                                </h4>
                            </div>
                        </li>
                    </ul>
            </div>
        </div>
    </section>
  )
}

export default MoreInfo