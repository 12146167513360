import React from 'react'
import p1 from '../assets/images/1.jpg'
import p2 from '../assets/images/2.jpeg'
import p3 from '../assets/images/3.jpeg'
import p4 from '../assets/images/4.jpeg'
import p5 from '../assets/images/5.jpeg'
function Circle() {
  return (
    <div className="z-[-10] bg-gradient-to-r from-red-800 to-red-400 w-full h-screen flex flex-col-reverse md:flex-row justify-center items-center overflow-hidden">
      <div className="w-full md:w-1/2 flex justify-center items-center transform scale-75 md:scale-125 translate-x-20 md:translate-x-40 translate-y-0 md:-translate-y-20">
          <img class="w-80 h-80 rounded-full border-4 border-white shadow-[0_0_15px_rgba(0,0,0,0.5)]"
              src={p1}
              alt=""/>

          <div class="h-[28rem] w-[28rem] flex justify-between items-center absolute border border-gray-800 rounded-full animate-spin-slow anim-8s">
            <div class="bg-white overflow-hidden rounded-full h-10 w-10  transform -translate-x-5 ">
                  <img class="h-10 w-10 object-center animate-rotate-img anim-8s"
                      src={p2}
                      alt=""/>
            </div>
            <div class="bg-white overflow-hidden rounded-full custom-shadow h-10 w-10 transform translate-x-5 ">
                    <img class="h-10 w-10 object-center animate-rotate-img anim-8s"
                         src={p3}
                         alt=""/>
                </div>
             
          </div>
          <div class="h-[36rem] w-[36rem] flex justify-between items-center absolute border border-gray-800 rounded-full animate-spin-slow ">


                <div class="flex justify-between items-center h-full w-full">
                    <div
                         class="bg-white overflow-hidden rounded-full h-10 w-10  transform translate-x-8 translate-y-40 ">
                        <img class="h-full w-full object-center animate-rotate-img "
                             src={p4}
                             alt=""/>

                    </div>
                    <div
                         class="bg-white overflow-hidden rounded-full  h-10 w-10 transform -translate-x-8 -translate-y-40 ">
                        <img class="h-full w-full object-center animate-rotate-img"
                             src={p5}
                             alt=""/>
                    </div>
                </div>


                <div class="absolute flex justify-between items-center h-full w-full">
                    <div
                         class="bg-white overflow-hidden rounded-full h-10 w-10  transform translate-x-8 -translate-y-40">
                        <img class="h-full w-full object-center animate-rotate-img "
                             src={p2}
                             alt=""/>

                    </div>
                    <div
                         class="bg-white overflow-hidden rounded-full  h-10 w-10 transform -translate-x-8 translate-y-40 ">
                        <img class="h-full w-full object-center animate-rotate-img "
                             src={p1}
                             alt=""/>
                    </div>
                </div>
            </div>
    </div>
  </div>

  )
}

export default Circle