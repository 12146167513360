import React from 'react'
import LocationCard from './LocationCard'
import p1 from '../../assets/images/17.jpeg'
import p2 from '../../assets/images/18.jpeg'
import p3 from '../../assets/images/19.jpeg'
import School2Card from './School2Card'
const data = {
    item2:{
        title: 'Inchiriere echipament',
        description: 'In cazul unui curs rezervat, pentru inchirieri, vă rugăm să veniți cu 20 de minute înaintea de inceperea cursului.',
        img : p1
    },
    item3:{
        title: 'Alte intrebari',
        description: 'Ai întrebări? Contactează-ne, iar noi îți vom oferi toate răspunsurile de care ai nevoie!',
        img : p2
    },
    item4:{
        title: 'Distinctii',
        description: 'Scoala NDY notata cu 9 la SOIMII SPORTULUI',
        img : p3
    }
}
function School2() {
  return (
    <div className="w-screen px-10 pt-[4rem] mt-[6rem]">
        <div className="flex flex-wrap justify-center items-center">
            <LocationCard/>
            <School2Card title={data.item2.title}
                          description={data.item2.description}
                          img={data.item2.img}/>
            <School2Card title={data.item3.title}
                          description={data.item3.description}
                          img={data.item3.img}/>
            <School2Card title={data.item4.title}
                          description={data.item4.description}
                          img={data.item4.img}/>
        </div>
    </div>
  )
}

export default School2