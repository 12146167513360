import React from 'react'
import Footer from '../components/Footer/Footer'
import Gallery from '../components/Slopes/Gallery'
import Skipass from '../components/Slopes/Skipass'
import SlopeHero from '../components/Slopes/SlopeHero'
import SlopeMap from '../components/Slopes/SlopeMap'
import SlopesBanner from '../components/Slopes/SlopesBanner'

function Slopes() {
      return (
        <div>
            <SlopeHero/>
            <SlopeMap/>
            <SlopesBanner/>
            <Skipass/>
            <Gallery/>
        </div>
  )
}

export default Slopes