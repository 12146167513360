import React from 'react'
import './LocationCard.css'
function LocationCard() {
  return (
    <div className="flex flex-col mx-2 justify-center items-center">
        <div className="location bg-red-500 flex justify-center relative before:flex before:bg-white before:rounded-full after:bg-[#372c4466]"/>
        <div className="text-center font-bold mt-[5rem] text-[1.5em]">Punct de intalnire</div>
        <div className="text-center font-light mt-2">Centrul de inchirieri NDY, cu 10 minute înaintea cursului.</div>
    </div>
  )
}

export default LocationCard