import React from 'react'
import { useNavigate } from 'react-router'
import './BannerBook.css'
function BannerBook() {
    const navigate = useNavigate()
  return (
      <div>
        <div className="bannerBook h-[15rem] w-screen justify-center flex bg-bannerBook bg-no-repeat bg-cover relative
                        before:absolute before:w-full before:h-full before:top-0 before:left-0">
            <div className="flex flex-wrap w-screen">
                <div className="flex flex-col justify-center text-center px-[1rem]">
                    <div className="text-[2em] md:text-[3em] font-semibold text-white z-[2]">
                        Fa o programare acum!
                    </div>
                    <div className="text-[1em] md:text-[1.5em] font-normal text-white z-[2]">
                        Acum e mai simplu ca niciodata! Suna si rezerva o ora cu unul dintre monitorii nostri.
                    </div>
                </div>
                <div className="flex justify-center items-center lg:w-1/4 text-center ml-[2rem] w-full">
                    <button onClick={() => navigate('../contact')}
                            className="mt-[-0.4rem] md:mt-[-2rem] z-[1] bg-white text-main-color h-[3.5rem] p-3 justify-center flex align-middle top-3/4 md:top-1/2 absolute text-[1m] md:text-[1.5em] font-bold rounded-xl text-center
                                        hover:shadow-[5px_20px_50px_#FF2400]">
                            Programare online
                    </button>
                </div>
            </div>

        </div>
    </div>
  )
}

export default BannerBook