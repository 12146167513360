import React from 'react'
import p1 from '../../assets/images/89.jpeg'
import School2Card from '../School/School2Card'
import p2 from '../../assets/images/121.png'
import p3 from '../../assets/images/86.jpeg'
import p4 from '../../assets/images/91.jpeg'
import p5 from '../../assets/images/92.jpeg'
import RentingCard from './RentingCard'

const data = [
    {
        title: 'Pret',
        description:'Prețuri atractive, incepând de la 45 LEI / zi',
        img: p2,
    },
    {
        title: 'Igienizare zilnica',
        description: 'Dupa fiecare inchiriere, intertorul claparului este dezinfectat',
        img: p4,
    },
    {
        title: 'Mentenanta',
        description: 'Acordam atentie starii echipamentului. Canturi ascutite & talpa reparata periodic',
        img: p3,
    },
    {
        title: 'Calitate',
        description:'Echipamente de la branduri de renume, reinnointe anual',
        img:p5,
    },
]
function RentingCharacteristics() {
  return (
    <div className="w-screen h-screen overflow-hidden  mt-[5rem]">
        <div className="w-full h-full lg:h-2/3 relative bg-[#00000066]">
            <div class="absolute top-0 right-0 bottom-0 left-0 overflow-hidden z-[-200] bg-blend-hard-light">
                        <img src={p1}
                            className="campImg absolute w-full h-full object-cover animate-zoomIn z-[-100]"/>
            </div>
            <div className="flex flex-col absolute top-0 left-0 w-full h-full">
                <div className="w-full px-[1em] md:px-[4em] text-white font-normal text-[2rem] md:text-[2.5rem]  text-center mt-[1rem] mb-[3rem] lg:mt-[3rem]">
                    De ce sa alegi centrul de inchirieri
                    <span className="uppercase text-main-color font-extrabold mx-[0.8rem]">Ndy</span>
                    ?
                </div> 
                <div className="flex flex-wrap justify-center align-middle">
                    {
                        data.map((item, idx) => {
                            return(
                                <RentingCard title={item.title}
                                             description={item.description}
                                             img={item.img}
                                             key={idx}/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default RentingCharacteristics