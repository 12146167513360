import React from 'react'
import { useEffect, useRef } from 'react';
import ServiceCard from './ServiceCard';
import p1 from '../../assets/images/57.jpeg'
import p2 from '../../assets/images/55.jpeg'
import p3 from '../../assets/images/58.jpeg'
import p4 from '../../assets/images/56.jpeg'
import './Services.css'

const services = [
    {
        name: 'Scoala de ski',
        img: p1,
        description: 'Va asteptam cu cursuri de ski într-un mediu sigur, creativ și adaptabil la nevoile clienților.',
        link: '/school',
        faClass: 'ski',
    },
    {
        name:'Scoala de snowboard',
        img: p2,
        description: 'Cursuri adaptate la toate nivelurile tehnice de snowboard: incepator, mediu si avansat',
        link: '/school',
        faClass: 'snowboarding',
    },
    {
        name: 'Centru de inchirieri',
        img: p3,
        description: 'Deschis zilnic in intervalul orar 10:00 - 19:00',
        link: 'renting',
        faClass: 'home',
    },
    {
        name: 'Bar',
        img: p4,
        description: 'O gama variata de produse: bauturi racoritoare, tatratea, porumb fiert, cafea, vin fiert si lista continua',
        link: '/',
        faClass: 'coffee',
    },
]
var typedTextSpan = null;
const textArray = ["Scoala de ski", "Scoala de snowboard", "Centru de inchirieri", "Bar"];
//the delay before typing the next character
const typingDelay = 200;
// typing will slower than erasing
const erasingDelay = 100;
// delay between the current and next string
const newTextDelay = 2000;
// keep track of current sting and character
let textArrayIndex = 0;
let charIndex = 0;

function type() {
    if(charIndex < textArray[textArrayIndex].length) {
        typedTextSpan.textContent += textArray[textArrayIndex].charAt(charIndex);
        charIndex++;
        setTimeout(type, typingDelay);
    }
    else {
        setTimeout(erase, newTextDelay);
    }
}

function erase(){
    if(charIndex > 0){
        typedTextSpan.textContent = textArray[textArrayIndex].substring(0, charIndex-1);
        charIndex--;
        setTimeout(erase, erasingDelay);
    }
    // type the first character from the next string
    else {
        textArrayIndex++;
        if(textArrayIndex >= textArray.length)
            textArrayIndex = 0;
        setTimeout(type, typingDelay + 1100);
    }
}

function ServicesHome() {
  const typedRef = useRef(null);
  useEffect(() => {
    typedTextSpan = typedRef.current;
    setTimeout(type, newTextDelay);
  }, [typedRef]);
  return (
    <div className="flex flex-col mt-[-4rem]">
            <h3 className="text-center text-[1.75em] mb-[0.5rem] font-medium">Descopera</h3>
            <h1 className="text-center text-[2.5em] mb-[0.5rem] font-medium">Serviciile RancaNDY</h1>
            <h4 className="text-center  leading-[0.1rem] overflow-hidden p-[0.5rem]">
                <span className="typed-text text-main-color font-bold text-[1.5rem]" 
                      ref={typedRef}></span>
                <span class="inline-block w-[3px]bg-[#ccc] ml-[0.1rem]">&nbsp;</span>
            </h4>
            <div className="items-center justify-center">
                <div className="justify-center flex flex-wrap w-full">
                    {
                        services.map((item, idx) => {
                            return(
                                <ServiceCard key={idx} card={item}/>
                            )
                        })
                    }
                </div>
 
            </div>
    </div>
  )
}

export default ServicesHome