import React from 'react'
import School1 from '../components/School/School1'
import p1 from '../assets/images/15.jpeg'
import p2 from '../assets/images/76.jpeg'
import p3 from '../assets/images/70.jpeg'
import p4 from '../assets/images/60.jpeg'
import p5 from '../assets/images/57.jpeg'
import p6 from '../assets/images/75.jpeg'
import p7 from '../assets/images/66.jpeg'
import p8 from '../assets/images/62.jpeg'
import School2 from '../components/School/School2'
import School3 from '../components/School/School3'
import School4 from '../components/School/School4'
import HexagoneGrid from '../components/School/HexagoneGrid'
import Footer from '../components/Footer/Footer'
const data = {
    school1: {
        title: 'Scoala de ski & snowboard',
        subtitle: 'ȘCOALĂ SKI COPII & ADULTI',
        description1: 'Pentru experiențe inedite, indiferent de vârstă, monitorii nostri te asteapta pe partie. Acestia sunt pregătiți să isi adapteze stilul de predare astfel inca sa satisfaca nevoile oricui si urmaresc ca învățarea sa se faca într-o atmosferă prietenoasă.',
        description2: 'Din dorinta ca un public cât mai larg sa practice un sport de iarna, începem cu cei mici si continuam cu adulti de orice varsta.',
        img: p1,
    }
}
const photosHexagone = [
  {
    img: p2,
    title: 'Bianca',
    description: '',
  },
  {
    img: p3,
    title: '3 anisori',
    description: 'Prima ora de ski',
  },
  {
    img: p7,
    title: 'Cristiana',
    description: '',
  },
  {
    img: p5,
    title: 'Petrica & Doky',
    description: 'Copiii tai pot invata in paralel.',
  },
  {
    img: p6,
    title: 'Ovi & Bianca & Razvan',
    description: 'Vino cu intreaga familie la ski!',
  },
  {
    img: p4,
    title: 'Doky',
    description: 'Rocking it!',
  },
  {
    img: p8,
    title: '',
    description: 'Lectii de grup',
  },
]
function SchoolInfo() {
  return (
    <div className="">

        <School1 title={data.school1.title}
                 subtitle={data.school1.subtitle}
                 description1={data.school1.description1}
                 description2={data.school1.description2}
                 img={data.school1.img}/>
        <School4/>
        <School2/>
        <div id="divider" className="my-[3rem] rounded-full ring-2 ring-gray-200 lg:w-1/2 lg:mx-auto "></div>
        <HexagoneGrid items={photosHexagone}/>
        <div id="divider" className="my-[3rem] rounded-full ring-2 ring-gray-200 lg:w-1/2 lg:mx-auto "></div>
        <School3/>
    </div>
  )
}

export default SchoolInfo