import React from 'react'
function School3() {
  return (
    <div className="w-screen overflow-hidden">
        <svg xmlns="http://www.w3.org/2000/svg" 
             xmlnsXlink="http://www.w3.org/1999/xlink" 
             preserveAspectRatio="xMidYMin slice" 
             viewBox="0 0 600 220"
             className="scale-60 w-screen">
            <path id="a" fill="none" stroke="#d3d3d3" stroke-width="10" d="M50 100s50 32 100-14 50 87 100-10 50 5 100 60 50-49 100 30 50-79 100-70"/>
            <circle cx="50" cy="100" r="15" fill="#d3d3d3"/>
            <circle cx="550" cy="96" r="15" fill="#d3d3d3"/>
            <text fill="#d3d3d3" font-family="monospace" font-size="20">
                <tspan x="30" y="150"> Incepator </tspan>
                <tspan x="515" y="145"> Avansat</tspan>
            </text>
                <circle r="15" fill="red">
                    <animateMotion dur="6s" repeatCount="indefinite">
                        <mpath xlinkHref="#a"/>
                    </animateMotion>
                </circle>
                <text fill="red" font-family="monospace" font-size="10">
                <tspan x="0" y="-20" text-anchor="middle"> Tu alaturi de echipa NDY </tspan>
                <animateMotion dur="6s" repeatCount="indefinite">
                    <mpath xlinkHref="#a"/>
                </animateMotion>
            </text>
        </svg>
    </div>
  )
}

export default School3