import React from 'react'

function PriceCard({equipments, img, alt}) {
  return (
   <div className="flex flex-col mx-[3rem] w-[23rem] mb-10 rounded-xl  shadow-xl backdrop-blur-xl hover:-translate-y-2 hover:shadow-2xl transition-all duration-300">
       <div className="flex flex-col">
           <div className="h-[13rem]">
               <img src={img} alt={alt} title={alt} className="h-full object-cover w-full rounded-t-xl"/>
           </div>
           {
               equipments.map((item, idx) => {
                    return(
                        <div className="flex flex-row justify-center mt-[0.5rem] mb-[0.5rem]" key={idx}>
                            <div className="font-bold">{item.title}</div>
                            <div className="text-main-color ml-[0.5rem]">{item.price}</div>
                        </div>
                    )
               })
           }
       </div>
    </div>
  )
}

export default PriceCard