import React from 'react'
import { motion } from 'framer-motion';
import './Hero.css'
import p1 from '../assets/images/1.jpg'
function Hero() {
  return (
    <div className="HeroImgShape z-0 w-full  h-screen before:block before:z-[1] before:w-full before:h-screen before:bg-hero-before">
         <div className="about absolute top-0 right-0 bottom-0 left-0 w-full overflow-hidden z-[-1] h-full">
                <img src={p1} 
                    className="absolute w-full h-full object-cover"/>
          </div>
        <div className="px-6 max-w-md absolute top-1/3 flex-col flex justify-between">
            <motion.h1 className="text-white pt-4 text-5xl lg:text-[5em] tracking-tighter leading-tight whitespace-nowrap"
                        initial={{ x: '+100vw' }}
                        animate={{ x: 0 }}
                        transition={{ stiffness: 400 }}> Scoala de ski
            </motion.h1>
            <h1 className="Headline animate-title pt-4 text-5xl lg:text-[5rem] tracking-tighter leading-[0.70em] whitespace-nowrap">
                RancaNDY
            </h1>
        </div>
    </div>
    
  )
}

export default Hero