import React from "react";

export const SidebarData = [
    {
        title: 'Despre Noi',
        path: '/',
    },
    {
        title: 'Scoala de Ski&Snowboard',
        path: '/school',
    },
    {
        title: 'Echipa NDY',
        path: '/team',
    },
    {
        title: 'Renting Center',
        path: '/renting',
    },
    {
        title: 'Despre partii',
        path: '/slopes',
    },
    {
        title: 'Contact',
        path: '/contact',
    },
]