import React from 'react'
import p1 from '../../assets/images/59.jpeg'
import p2 from '../../assets/images/60.jpeg'
import p3 from '../../assets/images/61.jpeg'
import p4 from '../../assets/images/62.jpeg'
import p5 from '../../assets/images/63.jpeg'
import p6 from '../../assets/images/64.jpeg'
import p7 from '../../assets/images/65.jpeg'
import p8 from '../../assets/images/66.jpeg'
import p9 from '../../assets/images/67.jpeg'
import p10 from '../../assets/images/55.jpeg'
import './HomeSlider.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "swiper/components/effect-coverflow"
import "swiper/components/pagination"

// import Swiper core and required modules
import SwiperCore, {
  EffectCoverflow,Navigation,Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([EffectCoverflow,Pagination]);

const photos = [p1, p2, p3, p4, p5, p6, p7, p8, p9, p10]
function HomeSlider() {
  return (
        <div className="ImageSliderContainer mt-[-10rem] mb-[10rem] bg-white flex justify-center items-center">
            <Swiper effect={'coverflow'} grabCursor={true} centeredSlides={true} slidesPerView={'auto'} coverflowEffect={{
            "rotate": 20,
            "stretch": 0,
            "depth": 200,
            "modifier": 1,
            "slideShadows": true
            }} pagination={true} loop={true} className="w-full pb-[12.5rem] h-[31.25rem]">
                {
                    photos.map((item, idx) => {
                        return(
                            <SwiperSlide className='myswiper-slide' key={idx}>
                                <img src={item}
                                     className="absolute w-full h-full object-cover"/>
                            </SwiperSlide>
                        )
                    })
                }
           
        </Swiper>
        </div>
  )
}

export default HomeSlider