import React from 'react'
import { Link } from 'react-router-dom'
import './TeamCard.css'
function TeamCard({instructor}) {
  return (
  
        <div className="teamCard card">
            <div className="content">
                <h2 className="text-[#fff] uppercase text-[2.5em] leading-[1em]">
                    {instructor.name}
                </h2>
                <p className="text-[#fff]">
                    {instructor.description}
                </p>
                <Link to="/team">
                    Descopera echipa
                </Link>
            </div>
            <img src={instructor.img} 
                 alt={instructor.name}
                 className="absolute "/>
        </div>
 
  )
}

export default TeamCard