import React from 'react'

function GoogleMap() {
  return (
    <div className="w-screen h-[30rem] flex justify-center mt-[-7rem] md:mt-[-3rem] mb-[4rem] rounded-[0.625rem]">
        <div class="w-full md:w-1/2 h-full">
            <iframe className="contact2Map w-full h-full object-contain rounded-2xl shadow-[5px_20px_50px_rgb(0,0,0)]" 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2806.8555615041187!2d23.68757581537548!3d45.29114037909926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474da3793d9aa0db%3A0x3018f077ad2f93d9!2sSCOALA%20DE%20SKi%20Si%20SNOWBOARD%20NDY!5e0!3m2!1sro!2sro!4v1637656529542!5m2!1sro!2sro" 
                    allowfullscreen="" 
                    loading="lazy">
            </iframe>
        </div>
    </div>
  )
}

export default GoogleMap