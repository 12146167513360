import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  date: "",
  bookings: [],
  bookingsLoading: false,
  bookingsLoaded: false,
  error: "",
};

const bookingsSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loadBookingsPending: (state, { payload }) => {
      state.bookingsLoading = true;
      state.date = payload; 
    },
    loadBookingsSuccess: (state, { payload }) => {
      state.bookingsLoading = false;
      state.bookingsLoaded = true;
      state.bookings = payload;
    },
    loadBookingsFail: (state, { payload }) => {
      state.bookingsLoading = false;
      state.error = payload;
    },

    dayReset: (state) => {
      state = initialState
    },
  },
});

const { reducer, actions } = bookingsSlice;

export const { loadBookingsFail, loadBookingsSuccess, loadBookingsPending, dayReset} = actions;

export default reducer;