import React from 'react'
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
function Menu({menuList}) {
  return (
    <div className="flex flex-col font-medium mx-[3rem]">
        <h2 className="flex text-white pb-2 text-[1.5em]">
            <span className="icon flex items-center justify-center text-main-color">
                <Icon icon="entypo:text-document"/>
            </span>
            Menu
        </h2>
        <div className="flex flex-col">
            {
                menuList.map((item, idx) => {
                    return(
                        <div className="flex text-[rgba(255,255,255,.3)] pt-1">
                            <Link to={item.link}
                                  className="hover:text-white">
                                  {item.title}
                            </Link>
                        </div>
                    )
                })
            }
        </div>
    </div>
  )
}

export default Menu