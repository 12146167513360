import React from 'react'

function Banner() {
  return (
    <div className="max-w-screen">
        <div className="h-[15rem] lg:h-[20rem] w-screen justify-center flex bg-gray-700">
            <div className="flex flex-col justify-center text-white">
                <div className="flex justify-center uppercase mb-[2rem] text-[3em] font-light">
                    Ne gasesti 
                    <span className="font-bold ml-[0.6rem]">aici</span>
                </div>
                <div className="h-[1px] bg-main-color mb-[2rem]"/>
                <div className="flex justify-center text-[1.5em] mx-[6em]">
                    Spunem intotdeauna 
                    <span className="font-bold text-black mx-[0.6rem]">
                        DA
                    </span> 
                    aventurilor pe pârtie! Dacă dorești să ni te alături, iată unde ne găsești:
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default Banner