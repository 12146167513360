import React from "react";
import video from '../../assets/videos/1.mov'
const SlopeHero = ({appType, tagLine, description, mainActionText, extraActionText}) => {
  return (
    <div class="h-screen w-screen text-center text-white py-0 ">
      <div className="w-full h-ful">
        <video class="video-docker h-screen  w-screen object-cover" autoPlay muted loop>
                <source src={video} type="video/mp4"/>
        </video>
      </div>
     
       
    </div>
  );
};

export default SlopeHero;