import React from 'react'
import p1 from '../../assets/images/37.jpeg'
import cloud1 from './../../assets/images/38.jpeg'
import cloud2 from './../../assets/images/39.jpeg'
import cloud3 from './../../assets/images/40.jpeg'
import cloud4 from './../../assets/images/41.jpeg'
import cloud5 from './../../assets/images/42.jpeg'
import './AboutUs.css'
function AboutUs({data}) {
  return (
    <div className="h-screen w-screen max-w-screen mt-[6rem]">
        <div className="h-3/4 relative">
            <div className="about absolute top-0 right-0 bottom-0 left-0 w-full overflow-hidden z-[-1] h-full
                            before:content-[''] before:absolute before:top-0 before:left-0 before:right-0 before:bottom-0 before:z-[2]">
                <img src={p1} 
                    className="absolute w-full h-full object-cover opacity-[0.7]"/>
            </div>
            <div className="absolute top-0 left-0 grid grid-cols-1 md:grid-cols-2 h-full">
                <div className="hidden md:block"/>
                <div className="aboutText flex flex-col text-black mx-8 lg:mx-16">
                    <h2 className='descriptionSubHeadline items-center justify-center flex mb-[2rem] font-medium text-[2em]'>{data.subtitle}</h2>
                    <h1 className='descriptionHeadline text-[1.9em] md:text-[2em] lg:text-[2.5em] items-center justify-center flex mb-[4rem] font-semibold leading-[0.125rem] text-main-color'>{data.title}</h1>
                    <p className="lg:text-[1.1em]">{data.p1}</p>
                    <p className="lg:text-[1.1em]">{data.p2}</p>
                </div>
            </div>
            <div className="cloudsContainer absolute bottom-0 left-0 overflow-hidden flex flex-row mt-[4rem]">
                <img className="cloud1  max-h-[12.5rem]" src={cloud1}/>
                <img className="cloud2 max-h-[12.5rem]" src={cloud2}/>
                <img className="cloud3 max-h-[12.5rem]" src={cloud3}/>
                <img className="cloud4 max-h-[12.5rem]" src={cloud4}/>
                <img className="cloud5 max-h-[12.5rem]" src={cloud5}/>
            </div>
        </div>
    </div>
  )
}

export default AboutUs