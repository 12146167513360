import React from 'react'
import './School4.css'
import p1 from '../../assets/images/72.jpeg'
import p2 from '../../assets/images/83.jpeg'
import BannerBook from './BannerBook'
function School4() {
  return (
    <div className="w-screen">
        <div className="flex flex-col w-screen">
            <div className="grid grid-cols-1 md:grid-cols-2 h-screen w-full pt-[1rem] md:pt-[6rem]">
                <div className="imgSection relative">
                    <img src={p1}
                         className="absolute w-full h-full object-contain"
                         alt="Școală Ski Copii si Adulti Ranca" title="Școală Ski Copii si Adulti Ranca"/>
                </div>
                <div className="flex flex-col  md:justify-center">
                    <div className="school4Text leading-[0.2rem] text-[1.5rem] md:text-[2rem] mb-[5rem] mt-[1rem] justify-center flex">
                        Tariful unui curs de ski
                        <span className="font-light text-main-color ml-[2rem] justify-center flex">130RON</span>
                    </div>
                    <div className="school4Text leading-[0.2rem] text-[2rem] mb-[5rem] justify-center flex">
                        Durata
                        <span className="font-light text-main-color ml-[2rem] justify-center flex">
                            50 minute
                        </span>
                    </div>
                    <div className="school4Text leading-[0.2rem] text-[1.7rem] md:text-[1.6rem] lg:text-[2rem] mb-[4rem] md:mb-[5rem] justify-center flex">
                        Varsta minima recomandata
                        <span className="font-light text-main-color ml-[2rem]">3 ani</span>
                    </div>
                    <div id="divider" className="md:hidden mb-[3rem] rounded-full ring-2 ring-gray-200"></div>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 h-screen w-full mb-[3rem] md:pb-[6rem] mt-[-20rem] md:mt-[-5rem]">
                <div className="flex flex-col justify-center align-middle">
                    <div className="school4Text leading-[0.2rem] text-[1.5rem] md:text-[1.6rem] lg:text-[2rem] mb-[5rem] mt-[1rem] justify-center flex">
                        Tariful unui curs de snowboard
                        <span className="font-light text-main-color ml-[2rem]">140RON</span>
                    </div>
                    <div className="school4Text leading-[0.2rem] text-[2rem] mb-[5rem] justify-center flex">
                        Durata
                        <span className="font-light text-main-color ml-[2rem] justify-center flex">
                            50 minute
                        </span>
                    </div>
                    <div className="school4Text leading-[0.2rem] text-[1.7rem] md:text-[1.6rem] lg:text-[2rem] mb-[0rem] md:mb-[5rem] justify-center flex">
                        Varsta minima recomandata
                        <span className="font-light text-main-color ml-[2rem]">3 ani</span>
                    </div>
                </div>
                <div className="imgSection relative mt-[-2rem] md:mt-0">
                    <img src={p2}
                         className="absolute w-full h-full object-contain"
                         alt="Școală Snowboard Copii si Adulti Ranca" title="Școală Snowboard Copii si Adulti Ranca"/>
                </div>
            </div>
            <BannerBook/>
        </div>
    </div>
  )
}

export default School4