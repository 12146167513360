import React from 'react'
import CenterDescription from '../components/RentingCenter/CenterDescription'
import RentingCharacteristics from '../components/RentingCenter/RentingCharacteristics'
import RentingHero from '../components/RentingCenter/RentingHero'
import RentingPrices from '../components/RentingCenter/RentingPrices'
import p1 from '../assets/images/106.jpeg'
import Footer from '../components/Footer/Footer'
const center = {
  title: 'Fa o vizita la centrul',
  description: 'Nu mai este nevoie să vă gândiți la transportul echipamentelor proprii sau la achiziționarea unora noi. La centrul de inchirieri NDY, gasesti echipamente de la brand-uri recunoscute pentru calitatea produselor : Burton, Atomic, Fischer, Head, Salomon. Staff-ul nostru va sta oricand la dispozitie cu sfaturi in legatura cu alegerea echipamentului potrivit.',
  img: p1,
}
function RentingCenter() {
  return (
    <div>
      <RentingHero/>
      <RentingPrices/>
      <RentingCharacteristics />
      <CenterDescription info={center}/>
    </div>
  )
}

export default RentingCenter