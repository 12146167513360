import React from 'react'
import AboutUs from '../components/AboutUs/AboutUs'
import Countdown from '../components/Countdown/Countdown'
import Footer from '../components/Footer/Footer'
import Hero from '../components/Hero'
import HomeSlider from '../components/HomeSlider/HomeSlider'
import MoreInfo from '../components/MoreInfo/MoreInfo'
import ServicesHome from '../components/ServicesHome/ServicesHome'
import TeamSection from '../components/TeamSection/TeamSection'
const data = {
  about: {
    title: 'Scoala Ski&Snowboard',
    subtitle:'Ranca Ndy',
    p1: "Esti in cautarea unei scoli de ski sau snowboard? Ndy e destinatia finala. Formata din 7 iubitori ai sporturilor de iarna, membri ai Asociației Monitorilor Profesioniști de Ski din România, scoala noastra este alegerea perfecta, indiferent de varsta sau nivelul de pregatire al cursantului. Modalitatile de predare sunt diversificate si interactive, pentru a oferi clientilor o experienta unica intr-un ambient placut.",
    p2: "Iar pentru a va oferi un confort la superlativ, Ndy va pune la dispozitie centrul de inchiriere de echipamente, unde gasiti o gama variata de ski-uri, snowboard-uri, clapari si boots, de la branduri cunoscute."
  }
}

function Home() {
  return (
    <React.Fragment>
        <Hero className="h-full"/>
        <AboutUs data={data.about}/>
        <ServicesHome/>
        <TeamSection/>
        <MoreInfo/>
        <Countdown/>
        <HomeSlider/>
    </React.Fragment>
  )
}

export default Home