import React from 'react'
import '../AfterLogin.css'
import PriceCard from './PriceCard'
import p1 from '../../assets/images/105.jpeg'
import p2 from '../../assets/images/102.jpeg'
import p3 from '../../assets/images/104.jpeg'
const ski = [
    {
        title: 'Echipament complet:',
        price: '40 RON',
    },
    {
        title: 'Ski:',
        price: '30 RON',
    },
    {
        title: 'Clapari:',
        price: '30 RON',
    },
    {
        title: 'Bete:',
        price: '15 RON',
    },
]
const snowboard = [
    {
        title: 'Echipament complet:',
        price: '45 RON',
    },
    {
        title: 'Placa:',
        price: '30 RON',
    },
    {
        title: 'Boots:',
        price: '30 RON',
    },
    {
        title: '',
        price: '',
    },
]
const aux = [
    {
        title: 'Casca:',
        price: '20 RON',
    },
    {
        title: 'Ochelari:',
        price: '15 RON',
    },
    {
        title: 'Sanie:',
        price: '20 RON',
    },
]
function RentingPrices() {
  return (
    <div className="w-screen mt-[6rem]">
        <div className="flex flex-col">
            <div className="redirectTitle text-black font-normal text-[2rem] md:text-[2.5rem]  text-center mb-[3rem]">
                Servicii premium de închirieri echipamente ski și snowboard
            </div>
            <div className="flex flex-wrap justify-center">
                <PriceCard img={p1} equipments={ski} alt="Preturi inchiriere echipament ski"/>
                <PriceCard img={p2} equipments={snowboard} alt="Preturi inchiriere echipament snowboard"/>
                <PriceCard img={p3} equipments={aux} alt="Preturi inchiriere sanie, casca si ochelari"/>
            </div>
        </div>
    </div>
  )
}

export default RentingPrices