import React from 'react'
import Banner from '../components/Contact/Banner'
import ContactSection from '../components/Contact/ContactSection'
import GoogleMap from '../components/Contact/GoogleMap'
import Footer from '../components/Footer/Footer'
function Contact() {
  return (
    <div>
        <ContactSection/>
        <GoogleMap/>
    </div>
  )
}

export default Contact