import React from 'react'
import p1 from '../../assets/images/48.jpeg'
import p2 from '../../assets/images/49.jpeg'
import p3 from '../../assets/images/50.jpeg'
import p4 from '../../assets/images/51.jpeg'
import p5 from '../../assets/images/52.jpeg'
import p6 from '../../assets/images/53.jpeg'
import p7 from '../../assets/images/54.jpeg'
import p8 from '../../assets/images/122.jpeg'
import TeamCard from './TeamCard'

const instructors = [
    {
        name: 'Doky',
        description: 'Monitor ski si snowboard incepand din anul 2010 si pana in prezent, am acumulat o gama mare de experienta atat in domeniul skiului cat si al snowboardingului, cu persoane de aproximativ toate varstele.\
        Am inceput sa skiez inca din grupa mare, urmand ca dupa cativa ani ,in gimnaziu, sa invat si pe snowboard.\
        Sunt o persoana destul de energica, mereu cautand noi experiente , acest lucru motivand afinitatea mea pentru mai multe sporturi.\
        Dublu campion la Concursul National Universitar de Ski si Snowboard din Poiana Brasov,in anii 2017 si 2018 ,categoria snowboard si prima pozitie la categoria ski-slalom special in 2017.\
        Profesia mea este de inginer geodez, absolvent al Universitatii din Craiova, sectia Masuratori Terestre si Cadastru, in prezent fiind angajat in acest domeniu.\
        Sportul este recomandat in viata fiecarei persoane, astfel ne mentinem in forma dar avem parte si de distractie. Ne vedem pe partie !',
        img: p7,
    },
    {
        name: 'Petrica',
        description: 'Buna! Numele meu este Petrica. Sunt membru al “Asociatiei Monitorilor de Ski Profesionisti din Romania “ afiliată I.S.I.A - International Ski \
        Instructors Association, cu o bogată experiență, din anul 2012, începând cu copii de 3 ani.În viața de zi cu zi sunt de profesie inspector ITP , absolvent al Facutati de Mecanica din Craiova, \
        fondator al Asociatie X3M Adventure - X3M Aventura , participant in Campionatul National Individual de Hard \
        Enduro al Romaniei , membru vânător al AJVP Gorj, organizator de ture off road si enduro.',
        img: p2,
    },
    {
        name: 'Ovidiu',
        description: 'Numele meu este Ovidiu, am început să schiez de la 4 ani, chiar aici în Ranca. \
        Sunt absolvent al facultății de Educație Fizica și Sport, am jucat handbal de performanța, iar de curând am absolvit cursurile de instructor Fitness Scandinavia. \
        Muntele și zapada au fost mereu în sufletul meu, drept urmare din anul 2014 sunt membru al Asociației Monitorilor Profesioniști de Ski din România. \
        Ador activitățile ce implică copii, sunt fascinat de voința și puterea lor de învățare. \
        Iubesc mișcarea, muntele și zăpada, iubesc libertatea pe care ti-o oferă schiatul, sunt o persoana energica, răbdătoare și pregatită să împărtășesc pasiunea mea cu voi! \
        Ne vedem pe pârtiile din Rânca să descoperim împreună toate secretele schiatului și plăcerea sporturilor de iarnă!',
        img: p3,
    },
    {
        name: 'Tibi',
        description: `Buna! Ma numesc Tibi si am absolvit profilul sportiv la liceul Novaci. Am 27 de ani, am terminat Facultatea de Mecanica si imi desfasor
        activitatea in transporturi rutiere; insa fiind un impatimit al sporturilor, sunt si monitor ski .De altfel , fac parte din Asociatia 
        Monitorilor de Ski Profesionisti din Romania. Sunt o fire rabdatoare si optimista, mereu dispus sa impart tot ce stiu. Asa ca va astept la
        Ranca sa cucerim impreuna partia!`,
        img: p6,
    },
    {
        name: 'Bianca',
        description: "Buna! Numele meu este Bianca, am 23 de ani și am absolvit Facultatea de Automatica și Calculatoare din București. Lucrez ca .NET developer și în timpul rămas liber, sunt monitor de ski. Pasiunea pentru tot ceea ce înseamnă munte, ski și miscare m-a determinat sa devin monitor atestat, astfel ca din anul 2019, sunt membru al Asociației Monitorilor Profesioniști de Ski din România. \
        Rabdarea și pofta de joaca ma recomanda în rândul micuților și calmul și firea sociabila în rândul adulților.\
         \
          Aștept cu nerăbdare sa ne întâlnim pe partiile de la Ranca și sa va transmit măcar un sfert din dragostea mea contagioasa pentru ski.",
        img: p1,
    },
    {
        name: 'Razvan',
        description: `Salutare! ⛷ 
        Mă numesc, Surupăceanu Răzvan-Valeriu, am 23 de ani, sunt student la Facultatea de Educație Fizică 
        și Sport din Timisoara. Am ales să studiez această facultate pentru că sunt pasionat de activitățile
        sportive de orice fel, iar pe viitor îmi doresc o carieră în acest domeniu. De asemenea, sunt student în cadrul departamentului de pregătire 
        al personalului didactic.`,
        img: p4,
    },
    {
        name: 'Cristiana',
        description: 'Buna! Ma numesc Cristiana. Am absolvit Academia Navala "Mircea cel Batran". Sunt o persoana dinamica, sociabila, \
        îmi place sa îmi petrec timpul în preajma copiilor și sa ii învăț lucruri frumoase. Schiez de la vârsta de 8 ani. \
        Din pasiunea pentru activitățile sportive de iarna, am devenit monitor de ski. Va astept cu entuziasm sa facem cunostiinta\
        iarna aceasta.',
        img: p5,
    },
    {
        name: 'Radu',
        description: 'Salutare ! Mă numesc Radu, am 26 ani și sunt pasionat de sporturile de iarnă, practicând schiatul de la vârsta de la 7 ani. Am absolvit FEFS Craiova, specializarea kinetoterapie. \
        In toți anii petrecuți pe pârtie am constatat că nu pot să stau departe de munte, așadar am ales sa particip la cursul de specializare monitor ski. De atunci sunt pe pârtiile din Rânca dornic să vă învăț din experiența mea, de la mic la mare. Sunt o fire răbdătoare și îmi doresc foarte mult să învăț copiii să schieze și să simtă bucuria sporturilor de iarnă.\
        Vă aștept cu drag pe pârtiile din Rânca !',
        img: p8,
    },
 
]
function TeamSection({instructor}) {
  return (
    <div className="w-screen mt-[8em]">
      <div className="flex flex-wrap justify-center align-middle">
        {
            instructors.map((item, idx) => {
                return(
                    <TeamCard key={idx} instructor={item}/>
                )
            })
        }
      </div>
    </div>
  )
}

export default TeamSection