import React, { useContext } from 'react'
import logo from '../../assets/images/logo.jpg'
import GlobalContext from '../../context/GlobalContext'
import dayjs from 'dayjs'

function CalendarHeader() {
    const {monthIndex, setMonthIndex} = useContext(GlobalContext)
    function handlePrevMonth(){
        setMonthIndex(monthIndex - 1)
    }
    function handleNextMonth(){
        setMonthIndex(monthIndex + 1)
    }
    function handleResetCurrentMonth() {
        setMonthIndex(dayjs().month());
      }
    return (
        <header className="px-4 py-2 flex items-center">
          
            <button 
                onClick={handleResetCurrentMonth}
                className="border rounde py-2 px-4 mr-5">Astazi</button>
            <button onClick={handlePrevMonth}>
                <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
                    chevron_left
                </span>
            </button>
            <button onClick={handleNextMonth}>
                <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
                    chevron_right
                </span>
            </button>
            <h2 className="ml-4 text-xl text-gray-500 font-bold">
                {dayjs(new Date(dayjs().year(), monthIndex)).format(
                "MMMM YYYY"
                )}
            </h2>
        </header>
  )
}

export default CalendarHeader