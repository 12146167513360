import React from 'react'
import p1 from '../../assets/images/117.jpeg'
import p2 from '../../assets/images/photo60.png'
import p3 from '../../assets/images/photo58.png'
import p4 from '../../assets/images/photo59.png'
import p5 from '../../assets/images/photo57.png'
import './WebcamButton.css'
const data = [
    'M1 – usoara, 650 m',
    'M2 – medie, 500 m',
    'M3 – medie, 710 m',
    'M4 – medie, 350 m',
    'L 1-3 – usoara, 910 m',
]
const prices = [
    {
        text:'skipass 24 puncte',
        price:'40 lei',
    },
    {
        text:'skipass 48 puncte',
        price:'70 lei',
    },
    {
        text:'skipass 96 puncte',
        price:'150 lei',
    },
    {
        text:'skipass 192 puncte',
        price:'250 lei',
    },
]
function Skipass() {
  return (
    <div className="w-screen mt-[6rem]">
        <div className="flex flex-wrap justify-center">
            <div className="flex flex-col w-screen md:w-1/2">  
                <div className="w-full relative">
                    <img src={p2}
                         className="w-full absolute top-0 left-0"/>
                    <img src={p3}
                         className="w-full absolute top-0 left-0"/>
                    <img src={p4}
                         className="w-full absolute top-0 left-0"/>
                </div>
                <div className="flex flex-col w-full justify-center z-[3] md:z-0">
                    <div className="redirectTitle text-black font-normal text-[2rem] md:text-[2.5rem]  text-center mb-[3rem]">
                        Tarife skipass
                    </div>
                    {prices.map((item, idx) => {
                        return(
                            <div className="text-main-color font-bold md:font-light uppercase justify-center flex redirectTitle text-[1.7em] md:text-[2em]">
                                {item.text}
                                <span className="text-black font-semibold ml-[1em]">{item.price}</span>
                            </div>
                        )
                    })}
                </div>
                <div className="btnRow items-center mt-[3rem] md:mt-[10rem] lg:mt-[15rem] z-[2] justify-center flex">
                    <div className="btnCol">
                        <a href="https://camere-live.ro/webcam-ranca-m1/" 
                           class="sswBtn btn" role="button">Webcam Ranca</a>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center w-screen md:w-1/2">
                {
                    data.map((item, idx) => {
                        return(
                            <div className="flex flex-row justify-center mb-[1rem]" key={idx}>
                                <img src={p1} className="h-[7rem] w-[7rem] rounded-full"/>
                                <div className="flex justify-center items-center ml-[1.5rem] font-semibold text-black">
                                    <div>{item}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </div>
  )
}

export default Skipass