import React from 'react'
import {FaFacebook, FaInstagram} from 'react-icons/fa'
import './InstructorCard.css'
function InstructorCard({name, title, img, facebook, instagram}) {
  return (
    <div className="flex flex-col max-w-sm md:max-w-md md:mx-10 mb-10">
        <img src={img} className="rounded-full w-[14.5rem] h-[15rem] border-[0.25rem] border-solid border-gray-600 object-cover"/>
        <h4 className="flex justify-center font-medium mt-4 text-[1.8em]">{name}</h4>
        <p className="flex justify-center font-light text-slate-600">{title}</p>
        <div className="flex flex-row justify-center">
            <div class="socialIcon facebook cursor-pointer flex justify-center scale-75">
                <a href={facebook}>
                    <i className="fa fa-facebook fa-2x"/>
                </a>
            </div>
            <div class="socialIcon instagram cursor-pointer flex justify-center scale-75">
                <a href={instagram}>
                    <i className="fa fa-instagram fa-2x"/>
                </a>
            </div>
        </div>
    </div>
  )
}

export default InstructorCard